<template>
  <div class="about-container">
    <div class="div-imgs">
      <div class="col-4">
        <img class="img-large" src="@/assets/images/ourstory1.webp" alt="" />
      </div>
    </div>
    <div class="card-about">
      <div class="">
        <h1 :style="{ ...getStyles.h1, color: getStyles.h1?.colorSecondary }" class="h2-about">Coming soon</h1>
      </div>
    </div>
  </div>
  <div flat class="card-enjoy">
    <q-card-section>
      <h3 :style="{ ...getStyles.h3 }" class="div-enjoy" style="">Enjoy the rhythm of our live DJ while you dine with us</h3>
      <div class="div-btn-enjoy">
        <a flat style="" class="btn-enjoy" target="_blank" href="getBranchConfigReserve" no-caps>Reserve</a>
      </div>
    </q-card-section>
  </div>
  <div class="fixed-menu">
    <ReserveBar />
  </div>
  <div class="fixed-call">
    <CallBar />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ReserveBar from "../layouts/ReserveBar.vue";
import CallBar from "../layouts/CallBar.vue";

export default {
  components: {
    CallBar,
    ReserveBar,
  },
  data() {
    return {
      reserveLink: "",
      title: "Our Story",
      description:
        "Discover the heart and soul of AvenueBlu. Learn about our passion for food, our commitment to exceptional service, and the unique story behind every dish.",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setTitle();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.setTitle();
    next();
  },
  methods: {
    setTitle() {
      document.title = this.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", this.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", this.description);
        document.head.appendChild(newMeta);
      }
    },

    fetchBranchConfig() {
      this.$store.dispatch("business/fetchBranchConfig").then((resp) => {
        this.reserveLink = resp.data.reserve_link;
      });
    },
  },
  created() {
    this.fetchBranchConfig();
  },
  computed: {
    ...mapGetters("business", ["getStyles", "getBranchConfigReserve"]),
  },
};
</script>

<style lang="scss" scoped>
.fixed-menu {
  display: none;
  height: 60px;
}
.fixed-call {
  display: none;
  height: 60px;
}
.img-large {
  width: 914px;
  object-fit: cover;
  height: 485px;
}
.card-enjoy {
  background-image: url("@/assets/images/enjoybg.webp");
  background-repeat: no-repeat;
  // height: 480px;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;
  font-family: Prata;
  background-size: cover;
}
.div-enjoy {
  padding-top: 150px;
  display: flex;
  justify-content: center;
  padding-bottom: 58px;
  font-size: 2rem;
  color: $accent;
}
.div-btn-enjoy {
  padding-bottom: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-enjoy {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background-color: $accent;
  color: $primary;
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  height: 51px;
  width: 171px;
  border-radius: 0 !important;
}
.p-about {
  font-size: 1rem;
  color: $primary;
  text-align: left;
  margin-top: 33px;
  margin-bottom: 0;
  text-align: justify;
  font-family: Manrope;
}
.about-container {
  margin-top: 90px;
}
.div-imgs {
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 485px;
  width: 100%;
}

.card-about {
  background-color: $background;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Stack elements vertically */
  padding: 115px 140px; /* Simplify padding */
  text-align: center; /* Center text inside */
}

.h2-about {
  font-size: 2.5rem;
  color: #1d1d1d;
  margin: 0;
  font-family: Prata;
  text-align: center; /* Center text */
}
.img {
  object-fit: cover;
  width: 100%;
  height: 485px;
}
@media screen and (max-width: 1024px) {
  .card-about {
    padding-left: 80px;
    padding-right: 80px;

    padding-top: 39px;
    padding-bottom: 115px;
  }
  .div-imgs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    height: 485px;
    width: 100%;
  }
  .img-large {
    width: 512px;
    object-fit: cover;
    height: 485px;
  }
}
@media screen and (max-width: 800px) {
  .col-4 {
    height: 358px;
  }
  .fixed-menu {
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    right: 0;
    border-top: solid #dee1e4 2px;
  }
  .fixed-call {
    left: 0;
    display: block;
    position: fixed; /* Cambia la posición a fija */
    bottom: 0; /* Alinea el componente en la parte inferior */
    /* Alinea el componente a la izquierda */
    width: 50%; /* Asegura que ocupe el ancho completo */
    z-index: 1000;
    /* Añade cualquier otro estilo que necesites, como sombras o padding */
    height: 60px;
    border-top: solid #dee1e4 2px;
  }
  .img-large {
    max-width: 430px;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .card-about {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 234px;
    padding-bottom: 60px;
  }
  .div-imgs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    height: 485px;
    width: 100%;
  }
  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .about-container {
    margin-top: 53px;
  }
  .h2-about {
    margin-top: 39px;
  }
}
</style>
